//处理ie内核白屏问题
import "babel-polyfill";
//VUE
import Vue from "vue";
import App from "./App.vue";
//路由
import router from "./router";
//VUEX
import store from "./store";
//AXIOS
import axios from "axios";
Vue.prototype.$axios = axios;
//封装请求
import {get,post,put,detach,getImage,postFile} from "./utils/request";
Vue.prototype.$get = get;
Vue.prototype.$post = post;
Vue.prototype.$put = put;
Vue.prototype.$delete = detach;
Vue.prototype.$getImage = getImage;
Vue.prototype.$postFile = postFile;
//时间格式插件
import moment from "moment";
Vue.prototype.$moment = moment;
//ANTD
import {
  Row,
  Col,
  Card,
  Button,
  Input,
  Layout,
  Menu,
  Dropdown,
  Icon,
  Spin,
  Modal,
  Form,
  Popover,
  message,
  Upload,
  Tree,
  Tooltip,
  Pagination,
  Drawer,
  Table,
  Tabs,
  Select,
  DatePicker,
  Tag,
  Divider,
  TreeSelect,
  Radio,
  InputNumber,
  Switch,
  ConfigProvider,
  Result
} from "ant-design-vue";
Vue.use(Card);
Vue.use(Row);
Vue.use(Col);
Vue.use(Button);
Vue.use(Input);
Vue.use(Layout);
Vue.use(Menu);
Vue.use(Dropdown);
Vue.use(Icon);
Vue.use(Spin);
Vue.use(Modal);
Vue.use(Form);
Vue.use(Popover);
Vue.use(Upload);
Vue.use(Tree);
Vue.use(Tooltip);
Vue.use(Pagination);
Vue.use(Drawer);
Vue.use(Table);
Vue.use(Tabs);
Vue.use(Tag);
Vue.use(TreeSelect);
Vue.use(Select);
Vue.use(DatePicker);
Vue.use(Divider);
Vue.use(Radio);
Vue.use(InputNumber);
Vue.use(Switch);
Vue.use(ConfigProvider);
Vue.use(Result);
message.config({ maxCount: 1 });
Vue.prototype.$message = message;
Vue.prototype.$modal = Modal;

Vue.config.productionTip = false;

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
