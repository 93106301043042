//AXIOS
import axios from "axios";

/* global publicUrl */

//执行登出操作
export function exeLogout(callback){
    axios({
        method: "get",
        headers: {
            "refresh_token": localStorage.getItem('RefreshAuthorization'),
        },
        url: publicUrl + "/logout",
    }).then(() => {
        localStorage.removeItem('RefreshAuthorization');
        localStorage.removeItem('AccessAuthorization');
        localStorage.removeItem('userName');
        localStorage.removeItem('role');
        callback();
    })
}