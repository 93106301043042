<template>
  <a-config-provider :locale="zh_CN">
    <div id="app">
      <router-view />
    </div>
  </a-config-provider>
</template>

<script>
import zh_CN from 'ant-design-vue/lib/locale-provider/zh_CN';
export default {
  data() {
    return {
      zh_CN,
    };
  }
}
</script>

<style>
html,body{
  width: 100%;
  height: 100%;
  min-width: 1280px;
  font-family: Microsoft YaHei,PingFang SC,Helvetica Neue,Hiragino Sans GB,Noto Sans,Tahoma,Arial,simsun,"sans-serif";
  font-size: 14px;
  margin: 0;
  padding: 0;
  background-color: #ebeef6;
}
#app {
  width: 100%;
  height: 100%;
  font-family: Microsoft YaHei,PingFang SC,Helvetica Neue,Hiragino Sans GB,Noto Sans,Tahoma,Arial,simsun,"sans-serif";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.fullContainer{
  width: 100%;
  height: 100%;
  float: left;
}
.table_action{
  color: #1890ff;
  cursor: pointer;
}
.table_action+.table_action{
  margin-left: 8px;
}
.table_action.disabled{
  color: #B1B1B1;
  cursor: not-allowed;
}
.default_main{
  width: calc(100% - 40px);
  height: calc(100% - 40px);
  float: left;
  box-sizing: border-box;
  margin: 20px;
  background-color: #FFFFFF;
}
.default_searchinput{
  width: 280px;
  float: left;
}
.f_left{
  float: left;
}
.f_right{
  float: right;
}
.tree_node{
  width: 100%;
  height: 30px;
  line-height: 30px;
  font-size: 13px;
  color: #444444;
  padding: 0 5px;
}
.tree_node:hover .tree_n_board{
  display: block;
}
.tree_n_title{
  width: calc(100% - 55px);
  float: left;
  overflow: hidden;
  text-overflow:ellipsis;
  white-space: nowrap;
}
.tree_n_board{
  width: 50px;
  height: 30px;
  float: left;
  display: none;
}
.tree_n_b_block{
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
}
.tree_n_b_block div+div{
  margin-left: 7px;
}
.tree_n_b_icon{
  font-size: 14px;
}
.tree_n_b_icon+.tree_n_b_icon{
  margin-left: 5px;
}
</style>

<style lang="less" scoped>
/deep/ .ant-spin-nested-loading{
  height: 100%;
}
/deep/ .ant-spin-container{
  height: 100%;
}
/deep/ .ant-modal-close-x{
  height: 52px;
  line-height: 52px;
  color: #FFFFFF;
}
/deep/ .ant-modal-header{
  padding: 15px 25px;
  background-color: #1b2a4e;
}
/deep/ .ant-modal-title{
  color: #FFFFFF;
  font-weight: bolder;
  font-size: 15px;
}
/deep/ .ant-modal-footer{
  padding: 9px 16px;
}
/deep/ .ant-modal-body{
  padding: 0px;
}
/deep/ .ant-table-thead > tr > th{
  padding: 8px 8px;
}
/deep/ .ant-table-tbody > tr > td {
  padding: 6px 8px;
}
/deep/ .ant-table{
  height: calc(100% - 64px);
  font-size: 13px;
}
/deep/ .ant-table-content{
  height: 100%;
}
/deep/ .ant-table-scroll{
  height: 100%;
  overflow-y: hidden;
}
/deep/ .ant-table-body-outer{
  height: calc(100% - 37px);
}
/deep/ .ant-table-fixed-left{
  height: calc(100% - 15px);
}
/deep/ .ant-table-fixed-right{
  height: calc(100% - 15px);
}
/deep/ .ant-table td { 
  white-space: nowrap;
}
/deep/ .ant-table-fixed-left .ant-table-body-outer .ant-table-body-inner{
  overflow-x: hidden;
}
/deep/ .ant-table-fixed-right .ant-table-body-outer .ant-table-body-inner{
  overflow-x: hidden;
}
/deep/ .ant-table-body{
  height: calc(100% - 36px);
  max-height: calc(100% - 36px) ;
  min-height: calc(100% - 36px);
}
/deep/ .ant-table-wrapper{
  height: 100%;
}
/deep/ .ant-table-placeholder {
  position: absolute;
  top: 39px;
  width: 100%;
  height: calc(100% + 26px);
  border: 0 !important;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 2;
}
/deep/ .ant-tag{
  margin: 0;
}
/deep/ .ant-tree.ant-tree-block-node li .ant-tree-node-content-wrapper{
  width: calc(100% - 20px);
  border-radius: 0;
}
/deep/ .ant-tree li .ant-tree-node-content-wrapper{
  padding: 0;
  height: 30px;
  transition: none;
}
/deep/ .ant-tree li{
  padding: 0;
}
/deep/ .ant-tree > li:first-child{
  padding-top: 0;
}
/deep/ .ant-tree li .ant-tree-node-content-wrapper.ant-tree-node-selected{
  background-color: #3CA0F6 !important;
}
/deep/ .ant-tree li .ant-tree-node-content-wrapper.ant-tree-node-selected .tree_node{
  color: #ffffff !important;
}
/deep/ .ant-tree li .ant-tree-node-content-wrapper:hover {
  background-color: #FFFFFF;
}
/deep/ .ant-tree li .ant-tree-node-content-wrapper:hover .tree_node{
  color: #2C8DF4;
}
/deep/ .ant-tree li span.ant-tree-switcher{
  width:20px;
  height:30px;
  padding-top: 2.5px;
}
/deep/ .ant-switch{
  height: 32px;
}
/deep/ .ant-switch::after{
  width: 28px;
  height: 28px;
  transition: none;
}
/deep/ .ant-switch-inner{
  margin-left: 33px;
}
/deep/ .ant-switch-checked .ant-switch-inner{
  margin-right: 33px;
  margin-left: 6px;
}
</style>