//VUE
import Vue from "vue";
//路由
import VueRouter from "vue-router";
Vue.use(VueRouter);
//跳转进度条
import NProgress from "nprogress";
import "nprogress/nprogress.css";
//页面配置文件
import pageConfig from './pageConfig';
//GET请求
import {get} from "../utils/request";

//补丁-修复路由向当前路由跳转时报错
const originalPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push(location, onResolve, onReject) {
  if (onResolve || onReject) return originalPush.call(this, location, onResolve, onReject);
  return originalPush.call(this, location).catch(err => err);
}
//静态路由（未登录状态）
const routes = [
  {
    path: "/",
    meta: { title: "鲲开码资产管理平台" },
    component: pageConfig['CommonLayout'],
    children: [
      {
        path: "/",
        redirect: "/login"
      },
      {
        path: "/login",
        meta: { title: "登录" },
        component: pageConfig['Login']
      },
      {
        path: "/404",
        meta: { title: "404" },
        component: pageConfig['Error']
      }
    ]
  }
];

//动态路由（孤子页等）
const dynamicRoutes = [];

//初始化history模式
const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes
});

//菜单-路由格式化处理
const formatRoutes = function (routes, routeData) {
  if (!routeData) {
    routeData = {
      path: '/backstage',
      component: pageConfig['BackstageLayout'],
      children: [
        {
          path: '/backstage',
          redirect: routes[0].component? routes[0].path:routes[0].children[0].path
        }
      ],
    };
  }
  routes.length && routes.forEach(route => {
    if(route.component) {
      route.component = pageConfig[route.component];
    }else{
      route.redirect = route.children[0].path;
    }
    routeData.children.push({
      path: route.path,
      component: route.component,
      meta: {
        title: route.title,
      },
    })
    if (route.children && route.children.length) {
      formatRoutes(route.children, routeData);
    }
  });
  return routeData;
};

//路由执行前守卫-获取权限菜单
let isFetchRemote = true;
router.beforeEach((to, from, next) => {
  NProgress.start();
  const loginFlag = localStorage.getItem('RefreshAuthorization');
  if(!loginFlag && to.path !== '/' && to.path !== '/login'){
    next({path: '/'});
  }
  else if(loginFlag && (to.path == '/' || to.path == '/login')){
    next({path: '/backstage'});
  }
  else if (isFetchRemote && to.path !== '/' && to.path !== '/login' ) {
    get("/menus",{})
    .then(function(res){
      let menuData = res.data;
      isFetchRemote = false;
      sessionStorage.setItem('menudata', JSON.stringify(menuData));
      const routeData = formatRoutes(menuData);
      router.addRoutes([routeData].concat(dynamicRoutes).concat([{path:'*',redirect: '/404'}]));
      next({
        path: to.path,
        query: to.query
      });
    })
  }
  else {
    if (to.meta.title) {
      document.title = '鲲开码资产管理平台 - '+to.meta.title;
    }
    next();
  }
})
//路由执行后守卫
router.afterEach(() => {
  NProgress.done();
})


export default router;
