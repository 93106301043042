//AXIOS
import axios from "axios";
//引用授权相关操作
import {exeLogout} from "./authorization";
//路由
import router from "../router";
//Antd通知和消息组件
import {notification,message} from "ant-design-vue";

//通知显示
export function showNotification(type,message,description,duration){
    notification.destroy();
    notification[type]({
        message: message,
        description: description,
        duration: duration
    });
}
//消息显示
export function showMessage(info){
    message.destroy();
    message.warning(info);
}

/* global basicUrl */

/**
 * 请求封装
 * 正在刷新令牌。当token失效时，把新令牌返回之前的请求都放到一个Promise函数集合里面
 * 当新令牌回来之后，再批量执行这些Promise函数。
 */
let SFTokenUtil = SFTokenUtil || {};
SFTokenUtil.isRefreshing = false;
SFTokenUtil.subscribers = [];
//默认Content-Type
axios.defaults.headers.post['Content-Type'] = 'application/json;charset=UTF-8';
//跨域请求参数
axios.defaults.withCredentials = true;

//请求配置拦截器加入短令牌
axios.interceptors.request.use(config => {
    config.headers.access_token = localStorage.getItem('AccessAuthorization');
    return config;
}, err => {
    //catch捕捉错误
    return Promise.reject(err); 
});

//请求回调拦截器
axios.interceptors.response.use(response => {
    return response;
}, error => {
    if(error.response){
        //403登录状态过期
        if(error.response.status===403){
            exeLogout(()=>{
                localStorage.setItem("LoginTimeout",true);
                router.go(0);
                SFTokenUtil.subscribers = [];
                SFTokenUtil.isRefreshing = false;
            });
        //402短令牌过期
        }else if(error.response.status===402){
            if(!SFTokenUtil.isRefreshing){
                SFTokenUtil.isRefreshing = true;
                axios({
                    method: "get",
                    headers: {
                        "refresh_token": localStorage.getItem('RefreshAuthorization'),
                    },
                    url: basicUrl + "/accessToken/refresh",
                }).then(res => {
                    //刷新短令牌成功
                    localStorage.setItem('AccessAuthorization',res.headers.access_token);
                    //执行队列里等待的请求
                    SFTokenUtil.subscribers.forEach(requestInSubscribers => {
                        requestInSubscribers();
                    })
                    //其他请求可以正常请求
                    SFTokenUtil.isRefreshing = false;
                })
            }
            //把请求返回令牌失效的所有请求放入队列-数组中，等新的令牌返回之后，再统一执行
            const retryOriginalRequest = new Promise(resolve => {
                //放入匿名函数，里面是执行Promise的resolve，resolve里面把本次请求重新执行一次
                SFTokenUtil.subscribers.push(() => {
                    //调用时要使用当前localStorage里的新令牌
                    error.config.headers.access_token = localStorage.getItem('AccessAuthorization');
                    resolve(axios(error.config));
                });
            });
            return retryOriginalRequest;
        //401账号或密码错误
        }else if(error.response.status===401){
            // console.log("账号或密码错误");
        //已知的后台错误
        }else if(error.response.status===500&&!(error.response.data.code===500500)){
            if(error.response.data.message!==undefined && error.response.data.message!==''){
                //显示提示信息
                showMessage(error.response.data.message);
            }
        //未知的后台错误
        }else{
            showNotification('error','网络异常','请稍后再试',2);
        }
    }else{
        showNotification('error','网络异常','请稍后再试',2);
    }
    return Promise.reject(error);
});
//GET请求
export function get(url,params){
    return new Promise((resolve,reject) => {
        axios.get(basicUrl+url,{ params:params })
        .then(res => { resolve(res) })
        .catch(err => { reject(err) })
    });
}
//POST请求
export function post(url,params){
    return new Promise((resolve,reject) => {
        axios.post(basicUrl+url,params)
        .then(res => { resolve(res) })
        .catch(err => { reject(err) })
    });
}
//PUT请求
export function put(url,params){
    return new Promise((resolve,reject) => {
        axios.put(basicUrl+url,params)
        .then(res => { resolve(res) })
        .catch(err => { reject(err) })
    });
}
//DELETE请求
export function detach(url,params){
    return new Promise((resolve,reject) => {
        axios.delete(basicUrl+url,{ params:params })
        .then(res => { resolve(res) })
        .catch(err => { reject(err) })
    });
}
//GETIMAGE请求
export function getImage(url){
    return new Promise((resolve,reject) => {
        axios.get(basicUrl+url,{ responseType:"arraybuffer" })
        .then(res => { resolve(res) })
        .catch(err => { reject(err) })
    });
}
//POSTFILE请求
export function postFile(url,params){
    return new Promise((resolve,reject) => {
        axios.post(basicUrl+url,params,{ responseType:"blob" })
        .then(res => { resolve(res) })
        .catch(err => { reject(err) })
    });
}