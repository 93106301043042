//未登录时的布局
const CommonLayout = () => import(/* webpackChunkName: "layout" */ "../layouts/CommonLayout");
//登陆后的后台布局
const BackstageLayout = () => import(/* webpackChunkName: "layout" */ "../layouts/BackstageLayout");
//登录页
const Login = () => import(/* webpackChunkName: "views" */ "../views/common/Login");
//404
const Error = () => import(/* webpackChunkName: "views" */ "../views/common/Error");
//资产列表
const AssetManagement_List = () => import(/* webpackChunkName: "views" */ "../views/backstage/AssetsList");
//申领
const AssetManagement_Apply = () => import(/* webpackChunkName: "views" */ "../views/backstage/ApplyManagement");
//派发
const AssetManagement_Distribution = () => import(/* webpackChunkName: "views" */ "../views/backstage/DistributionManagement");
//退库
const AssetManagement_Cancel = () => import(/* webpackChunkName: "views" */ "../views/backstage/CancelManagement");
//转移
const AssetManagement_Transfer = () => import(/* webpackChunkName: "views" */ "../views/backstage/TransferManagement");
//处置
const AssetManagement_Handle = () => import(/* webpackChunkName: "views" */ "../views/backstage/DisposeManagement");
//盘点
const AssetManagement_Inventory = () => import(/* webpackChunkName: "views" */ "../views/backstage/InventoryManagement");
const AssetManagement_InventoryDetail = () => import(/* webpackChunkName: "views" */ "../views/backstage/InventoryDetail");
const AssetManagement_InventoryWinRecord = () => import(/* webpackChunkName: "views" */ "../views/backstage/InventorySurplus");
//资产分类
const AssetConfig_Type = () => import(/* webpackChunkName: "views" */ "../views/backstage/TypeManagement");
//资产位置
const AssetConfig_Location = () => import(/* webpackChunkName: "views" */ "../views/backstage/LocationManagement");
//扩展信息
const AssetConfig_Continuation = () => import(/* webpackChunkName: "views" */ "../views/backstage/ExtendManagement");
//签字设置
const AssetConfig_Sign = () => import(/* webpackChunkName: "views" */ "../views/backstage/SignSetting");
//审批开关
const AssetConfig_Approval_Switch = () => import(/* webpackChunkName: "views" */ "../views/backstage/ApprovalSwitch");
//审批人配置
const AssetConfig_Approval_Setting = () => import(/* webpackChunkName: "views" */ "../views/backstage/ApprovalSetting");
//员工管理
const System_Staff = () => import(/* webpackChunkName: "views" */ "../views/backstage/EmployeeManagement");
//组织架构
const System_Organization = () => import(/* webpackChunkName: "views" */ "../views/backstage/OrganizationManagement");
//租户管理
const Tenant = () => import(/* webpackChunkName: "views" */ "../views/backstage/TenantManagement");

export default {
    CommonLayout,
    BackstageLayout,
    Login,
    Error,
    AssetManagement_List,
    AssetManagement_Apply,
    AssetManagement_Distribution,
    AssetManagement_Cancel,
    AssetManagement_Transfer,
    AssetManagement_Handle,
    AssetManagement_Inventory,
    AssetManagement_InventoryDetail,
    AssetManagement_InventoryWinRecord,
    AssetConfig_Type,
    AssetConfig_Location,
    AssetConfig_Continuation,
    AssetConfig_Sign,
    AssetConfig_Approval_Switch,
    AssetConfig_Approval_Setting,
    System_Staff,
    System_Organization,
    Tenant
};